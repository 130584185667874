<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 首页版心 -->
    <div id="invite-friend-layout">
      <div class="invite-friend-top">
        <!-- 导航条 -->
        <div style="position: relative; top: 32px;margin-left: 5%;margin-right: 5%; scroll;height: 13vh;">
          <img src="@/assets/common/time.png"  style="float: left;width: 23px;margin-right: 10px;" @click="$router.push('/inviteLog')"/>
          <span style="color: rgba(119, 117, 138, 1); float: left; font-size: 19px" @click="$router.push('/inviteLog')">{{$t('inviteFriend.i_inviteLog')}}</span>
          <img src="@/assets/common/return.png" @click="$router.push('/index')" style="float: right;width: 30px;margin-right: 10px;" />
        </div>

        

        <div style="text-align: center">
          <p style="font-size: 1.2rem; margin: -5px 0; color: rgba(255, 255, 255, 1)">{{$t('inviteFriend.i_everyFriend')}}</p>
          <p style="font-size: 1.5rem; font-weight: bold; margin: 15px 0;color: rgba(255, 255, 255, 1);margin-bottom: -10px">+ {{commission}}%{{$t('inviteFriend.i_friendsCommission')}}</p>
          <p style="font-size: 1.5rem; margin-bottom: 5px;color: rgba(255, 255, 255, 1); ">
            {{maxReward}} 
            <img src="@/assets/common/cashIcon.png"  style="width: 23px;" />
            {{$t('inviteFriend.i_total')}}
          </p>


          <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
            <div style="text-align: center;margin-left: 80px">
              <p style="font-size: 2rem;color: rgba(255, 255, 255, 1); height: 2vh;" >{{inviteCount}}</p>
              <p style="font-size: 20px;color: rgba(119, 117, 138, 1);">{{$t('inviteFriend.i_friends')}}</p>
            </div>
            <div style="text-align: center;margin-right: 80px">
              <p style="font-size: 2rem;color: rgba(255, 255, 255, 1);height: 2vh;">{{reward}}
                <img src="@/assets/common/cashIcon.png"  style="width: 23px;" />
              </p>
              <p style="font-size: 20px;color: rgba(119, 117, 138, 1);margin-right: 16px;">{{$t('inviteFriend.i_reward')}}</p>
            </div>
          </div>  
        </div>

        <div id="toggleElement" style="visibility: hidden; position: relative; background-color: rgba(255, 255, 255, 1);color: rgba(255,255,255,1); border-radius: 20px; height: 40px; width: 250px; top: 18px;margin: auto;">
          <span style="position: relative; top: 10px; color: rgba(1,1,1,1) ">{{displayValue}}</span>
        </div>

        <div style="margin: 0%;padding: 4% 5%;border-radius: 15px; margin-top: -10%">
          <!-- <div style="text-align: center; ">
              <div style="font-size: 17px;margin-left: -5px;margin-top: 12px;margin-bottom: 12px;">
                <span :class="inviteType == 1 ? 'leaderboardAct' : ''" @click="inviteType = 1;" style="margin: auto 4px;padding: 4px 8px 4px 6px; color: rgba(119, 117, 138, 1)">{{$t('链接邀请')}}</span>
                <span :class="inviteType == 2 ? 'leaderboardAct' : ''" @click="inviteType = 2;" style="margin: auto 4px;padding: 4px 8px 4px 6px; color: rgba(119, 117, 138, 1)">{{$t('邀请码邀请')}}</span>
                <span :class="inviteType == 3 ? 'leaderboardAct' : ''" @click="inviteType = 3;" style="margin: auto 4px;padding: 4px 8px 4px 6px; color: rgba(119, 117, 138, 1)">{{$t('填写邀请码')}}</span>
              </div>
          </div> -->

          <div  style="position: relative; margin-left: 2px; margin-top: 33px;">
                
                <div style="margin:auto; left: -136px;width: 45px;height: 45px;border-radius: 30px;position: relative;">
                  <span style="display: inline-block;;overflow: hidden;margin: 0;padding: 0;border-radius: 10px;margin-left: -8px; height: 100px; width: 100px; background-color: rgba(11, 128, 96, 1);margin-left: -11px; ">
                    <div style="color: rgba(255, 255, 255, 1); font-size: 13px; margin-top: 22px">{{$t('inviteFriend.i_shareYourLink')}}</div>
                  </span>
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: -16px; top: -3px; background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                      <span style="vertical-align: middle;font-size: 12px;">1</span>
                  </span>
                </div>
                <img src="@/assets/images/invite/arrow.png"  style="width: 10px; position: relative; top: -13px; left: -60px" />
                <div style="margin:auto;width: 45px;height: 45px;border-radius: 30px;position: relative; left: -45px; top: -85px">
                  <span style="display: inline-block;overflow: hidden;margin: 0;padding: 0;background-color: rgba(11, 128, 96, 1);border-radius: 10px; height: 100px; width: 100px; margin-right: 11px;margin-left: 16px;">
                    <div  style="font-size: 13px; margin-top: 25px; color: rgba(255, 255, 255, 1);">{{$t('inviteFriend.i_loginByLink')}}</div>
                  </span>
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: 7px; top:-3px;background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                      <span style="vertical-align: middle;font-size: 12px;">2</span>
                  </span>
                </div>
                <img src="@/assets/images/invite/arrow.png"  style="width: 10px; position: relative; top: -102px; left: 58px" />
                <div style="margin:auto;width: 45px;height: 45px;border-radius: 30px;position: relative; top:-172px; left:90px">
                  <span style="display: inline-block;overflow: hidden;margin: 0;padding: 0;background-color: rgba(11, 128, 96, 1);border-radius: 10px; height: 100px; width: 100px">
                    <div style="color: rgba(255, 255, 255, 1); font-size:13px; margin-top: 26px">{{$t('inviteFriend.i_unlock')}}</div>
                  </span>
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: -3px; top: -5px;background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                      <span style="vertical-align: middle;font-size: 12px;">3</span>
                  </span>
                </div>
                <el-button @click="inviteLinkDrawer = true" style="position: relative; top: -50px; width: 70%;border-radius: 10px;background-color: rgba(80, 214, 119, 1);color: rgba(1, 1, 1, 1);">{{$t('inviteFriend.i_shareLink')}}</el-button>
          </div>

          <!-- <div v-if="inviteType == 2" style="position: relative; margin-left: 2px; margin-top: 33px;">
                
                <div style="margin:auto; left: -136px;width: 45px;height: 45px;border-radius: 30px;position: relative;">
                  <span style="display: inline-block;;overflow: hidden;margin: 0;padding: 0;border-radius: 10px;margin-left: -8px; height: 100px; width: 100px; background-color: rgba(11, 128, 96, 1);margin-left: -11px; ">
                    <div style="color: rgba(255, 255, 255, 1); font-size: 13px; margin-top: 22px">{{$t('inviteFriend.i_ShareInvitationCode')}}</div>
                    <div style="position: relative; top: 8px; color: rgba(255, 179, 0, 1);">{{inviteCode}}</div>
                    <span @click="copyVal($event.currentTarget.previousElementSibling.innerText)" style="margin-left: 2%;">
                        <img src="@/assets/images/invite/copy.png"  style="width: 15px; position: relative; top: 18px; left: 0px" />
                    </span>
                  </span>
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: -16px; top: -3px; background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                      <span style="vertical-align: middle;font-size: 12px;">1</span>
                  </span>
                </div>
                <img src="@/assets/images/invite/arrow.png"  style="width: 10px; position: relative; top: -13px; left: -60px" />
                <div style="margin:auto;width: 45px;height: 45px;border-radius: 30px;position: relative; left: -45px; top: -85px">
                  <span style="display: inline-block;overflow: hidden;margin: 0;padding: 0;background-color: rgba(11, 128, 96, 1);border-radius: 10px; height: 100px; width: 100px; margin-right: 11px;margin-left: 16px;">
                    <div  style="font-size: 13px; margin-top: 25px; color: rgba(255, 255, 255, 1);">{{$t('inviteFriend.i_downloadApp')}}</div>
                  </span>
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: 7px; top:-3px;background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                      <span style="vertical-align: middle;font-size: 12px;">2</span>
                  </span>
                </div>
                <el-button @click="inviteLinkDrawer = true" style="position: relative; top: 10px; width: 70%;border-radius: 10px;background-color: rgba(80, 214, 119, 1);color: rgba(1, 1, 1, 1);">{{$t('inviteFriend.i_shareLink')}}</el-button>
          </div> -->

          <!-- <div v-if="inviteType == 3" style="position: relative; margin-left: 2px; margin-top: 33px;">
                
                <div style="margin:auto; left: -136px;width: 45px;height: 45px;border-radius: 30px;position: relative;">
                  <span style="display: inline-block;;overflow: hidden;margin: 0;padding: 0;border-radius: 26px;margin-left: -8px; height: 139px; width: 312px; background-color: rgba(11, 128, 96, 1);margin-left: 0px; ">
                    <div style="color: rgba(255, 255, 255, 1); font-size: 17px; margin-top: 22px">{{$t('inviteFriend.i_ShareInvitationCode')}}200
                      <img src="@/assets/images/invite/invitecash.png"  style="width: 18px; position: relative; top: 7px; left: -3px" />
                    </div>
                    
                    <el-input type="text" class="custom-input" v-model="inputValue" placeholder="只能填写一位好友邀请码" style=" width: 232px; margin-top: 21px" />
                  </span>
                  
                </div>
                    <el-button v-if="!disable" @click="claimRewards = true" style="position: relative; top: 122px; width: 70%;border-radius: 10px;background-color: rgba(80, 214, 119, 1);color: rgba(1, 1, 1, 1);">{{$t('all.i_claimRewards')}}</el-button>
                    <el-button v-else style="position: relative; top: 122px; width: 70%;border-radius: 10px;background-color: rgba(133, 134, 146, 1);color: rgba(1, 1, 1, 1);">{{$t('inviteFriend.i_alreadyInvited')}}</el-button>

          </div> -->
         
        </div>


        

      <el-dialog :title="$t('all.i_earnRewards')" :visible.sync="claimRewards" width="76%" margin-top="18hv" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false" custom-class="custom-dialog">
          <div style="background-color: rgba(40, 41, 67, 1); border-radius: 15px; height: 65px; margin-top: 10px;" >
            <div style="position: relative; top: 20px;font-size: 26px;color: rgba(80, 214, 119, 1);white-space: pre-wrap;word-break: break-word; text-align: center;">+200<img src="@/assets/common/cashIcon1.png"  style="width: 18px; position: relative; top: 0px; left: 3px" /></div>
          </div>
          <div style="text-align: center;">
            <el-button round type="primary" @click="claimRewards=false, validation() " style="width: 85%; background-color: rgba(80, 214, 119, 1) !important;border-radius: 12px; margin-top: 35px">
              {{$t('inviteFriend.i_claimNow')}}
            </el-button>
          </div>
        </el-dialog>
          
        

        <el-dialog :title="$t('convert.i_tips')" :visible.sync="tipsDialog" width="85%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
          <div style="margin-bottom: 6%;text-align: center;">
            <div style="font-size: 16px;color: #000;white-space: pre-wrap;word-break: break-word;">{{$t('inviteFriend.i_tipsDialogMsg2')}}</div>
          </div>
          <div style="text-align: center;">
            <el-button round type="primary" @click="tipsDialog = false;inviteLinkDrawer = true;" style="width: 60%;">
              {{$t('convert.i_ok')}}
            </el-button>
          </div>
        </el-dialog>

        <el-drawer size="40%" direction="btt" :with-header="false" :show-close="false" :visible.sync="inviteLinkDrawer">
          <div>
            <div style="overflow: hidden;margin-top: 5%;margin-left: 5%;margin-right: 5%;">
              <img :src="inviteInfo.appIcon" style="float: left;height: 50px;border-radius: 10px;" />
              <span style="float: left;margin-top: 5%;margin-left: 5%;font-weight: bold;color: black;">
                Bigbig Cash2
              </span>
            </div>
            <div style="background-color: rgba(223, 223, 227, 1);margin: 5%;padding: 3%;border-radius: 6px;">
              <span style="">
                <a :href="inviteLink" style="word-break: normal;overflow-wrap: break-word;">{{ inviteLink }}</a>
              </span>
            </div>
            <div>
              <el-button round type="primary" @click="copyVal(inviteLink)" style="width: 38%;background-color: rgba(0, 111, 255, 1);">
                <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
                &nbsp;
                <span style="font-size: 16px;">Copy</span>
              </el-button>
            </div>
          </div>
        </el-drawer>

        <!-- 初始任务状态Dialog -->
        <el-dialog :title="$t('convert.i_tips')" :visible.sync="initTaskStatusDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
          <div style="text-align: center;margin-bottom: 10%;">
            <div style="font-size: 16px;color: #000;word-break: normal;overflow-wrap: break-word;">{{ $t('all.i_pleaseInitTask') }}</div>
          </div>
          <div style="text-align: center;">
            <el-button round type="primary" @click="goTaskInfo()" style="width: 60%;">
              {{ $t('convert.i_ok') }}
            </el-button>
          </div>
        </el-dialog>

        <!-- 错误信息Dialog -->
        <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnPressEscape="false">
          <div style="text-align: center;margin-bottom: 10%;">
            <div style="font-size: 16px;color: #000;">{{ failMessage }}</div>
          </div>
          <div style="text-align: center;">
            <el-button round type="primary" @click="go()" style="width: 60%;">
              {{ $t('convert.i_ok') }}
            </el-button>
          </div>
        </el-dialog>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      inviteType: 1,
      userInfo: {
        uid: 0,
        avatar: "",
        cashNum: 0,
      },
      inputValue: "",
      failMessage: null,
      checkDialog: false,
      inviteInfo: {},
      inviteNum: {},
      inviteLinkDrawer: false,
      tipsDialog:false,
      inviteLink: "",
      inviteLogType: 1,
      inviteLogOne: [],
      busyOne: false,
      pageNumOne: 0,
      isLastOne: false,
      inviteLog: [],
      busy: false,
      pageNum: 0,
      isLast: false,
      initTaskStatusDialog: false,
      reward: null,
      inviteCount: null,
      maxReward: null,
      commission: null,
      inviteCode: null,
      disable: false,
      claimRewards: false,
      displayValue: "",
      isVisible: "visible"
    };
  },
  methods: {
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    },
    getInviteInfo() {
      this.isLoading = false;
      this.$axios({
        url: "/webCash/invite/info",
        method: "get",
        
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          // this.userInfo = result.data.data.userInfo;
          // this.$store.state.cashNum = result.data.data.userInfo.num;
          // this.inviteInfo = result.data.data.inviteInfo;
          // this.inviteNum = result.data.data.inviteNum;
          
          this.reward = result.data.data.reward;
          this.inviteCount = result.data.data.inviteCount;
          this.maxReward = result.data.data.maxReward;
          this.commission = result.data.data.commission;
          this.inviteCode = result.data.data.inviteCode;
          this.inviteLink = result.data.data.inviteLink;
        
          
          // this.inviteLink =
          //   window.location.origin +
          //   "/#/invite?tid=" +
          //   this.$route.query.tid +
          //   "&referrer=" +
          //   result.data.data.userInfo.inviteCode;
          // if (result.data.data.initTaskStatus == false) {
          //   this.initTaskStatusDialog = true;
          // }
        } else if (result.data.code == 0) {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
          this.$router.push("/index");
        }
      });
    },

    checkInviteCode() {
      
      this.$axios({
        url: "/webCash/can/input-code",
        method: "get",
        
      }).then((result) => {
        this.isLoading = false;
        if (result.data != null) {
          
          this.disable = result.data.data.disable;
          
        } else if (result.data == null) {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },

    validation() {
      
      this.$axios({
        url: "/webCash/validation",
        method: "get",
        params: {
          inviteCode: this.inputValue
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          
           this.displayValue = "邀请成功";
            var element = document.getElementById('toggleElement');
            element.style.visibility = 'visible';
            setTimeout(function() {
                element.style.visibility = "hidden";
            }, 2000); // 消失时间：2秒
            this.disable = true;
        } else if (result.data.code == 0) {
          
          if(result.data.message === "Invalid code"){
            this.displayValue = "邀请码无效";
            var element = document.getElementById('toggleElement');
            element.style.visibility = 'visible';
            setTimeout(function() {
                element.style.visibility = "hidden";
            }, 2000); // 消失时间：2秒
          }else if(result.data.message === "You have already helped, each user can only help once"){
            this.displayValue = "该已经被邀请";
            var element = document.getElementById('toggleElement');
            element.style.visibility = 'visible';
            setTimeout(function() {
                element.style.visibility = "hidden";
            }, 2000); // 消失时间：2秒
          }
        }
          
      });
    },
    goApp() {
      window.location.href = this.userInfo.appScheme;
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    goTaskInfo() {
      this.$router.push({
        path: "/taskInfo",
        query: {
          tid: this.$route.query.tid,
          referrer: this.$route.query.referrer
        }
      });
      this.initTaskStatusDialog = false;
    },
  },
  created() {
    this.getInviteInfo();
    this.checkInviteCode();
  }
};
</script>

<style>
#invite-friend-layout {
  max-width: 500px;
  margin: auto;
}

#invite-friend-layout .invite-friend-top {
  padding-bottom: 2%;
}

#invite-friend-layout .if-page-header {
  overflow: hidden;
  padding: 4% 4% 0 4%;
}

#invite-friend-layout .el-page-header__left::after {
  display: none;
}

#invite-friend-layout .i-gift-code-btn,
#invite-friend-layout .bottom-menu-bar .el-button {
  border: none;
  background: none;
}

#invite-friend-layout .el-drawer {
  border-radius: 20px 20px 0 0 !important;
}

#invite-friend-layout .inviteLogAct {
  color: rgba(32, 35, 42, 1) !important;
}

#invite-friend-layout .leaderboardAct {
  position: relative; /* 为伪元素定位做准备 */
  color: white !important; /* 保持文字颜色为白色 */
  border-radius: 30px; /* 保持圆角效果 */
}

#invite-friend-layout .leaderboardAct::after {
  content: ''; /* 伪元素内容为空 */
  position: absolute; /* 绝对定位 */
  left: 30%; /* 调整下划线起始位置 */
  bottom: -5px; /* 调整下划线与文字的距离 */
  width: 42%; /* 调整下划线的长度 */
  height: 3px; /* 下划线的粗细 */
  background-color: white; /* 下划线颜色 */
}

.custom-input .el-input__inner {
  background-color: rgba(13, 98, 75, 1);
  width: 232px;
  border-radius: 15px;
  color: rgba(255, 178, 0, 1);
  text-align: center;
}

.custom-input .el-input__inner::placeholder {
  color: rgba(53, 164, 134, 1);        /* 修改颜色 */
  font-size: 14px;    /* 修改字体大小 */
  text-align: center;
}

.custom-dialog .el-dialog__body {
  background-color:  rgba(47, 49, 78, 1) !important; /* 调整对话框主体的背景颜色 */
  height: 150px; /* 设置高度 */
  overflow: auto; 
}
  
.custom-dialog .el-dialog__header {
  background-color: rgba(47, 49, 78, 1) !important; /* 调整对话框头部的背景颜色 */
}

.custom-dialog .el-dialog__title {
  color:rgba(255,255,255,1) !important;
}

.custom-dialog .el-dialog {
  top: 50px; /* 从顶部开始的距离 */
  left: 50px; /* 从左边开始的距离 */
  margin: 0; /* 清除默认的 margin */
  position: relative;; /* 或使用 fixed，如果需要固定位置 */
}



</style>
