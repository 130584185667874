<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="convert-layout">
      <div class="c-page-header">
        <img src="@/assets/common/return.png" @click="$router.push('/index')" style="float: right;width: 30px;margin-top: 12px;margin-right: 10px;margin-bottom: 4px;" />
        <div style="float: left; margin-top: 15px;margin-left: 7px;">
          <!-- <div style="background-color: rgba(251, 236, 143, 1);padding: 3px 7px 4px 7px;border-radius: 20px;margin: 6px 8px 0 0;display: inline-block;">
            <span style="font-size: 14px;color: black;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(87, 36, 13, 1);font-weight: 600;">
              {{ $store.state.cashNum }}
            </span>
            <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
          </div> -->
          <img src="@/assets/common/time.png" style="width: 18px;vertical-align: middle;" />
          <div style="display: inline-block; margin-left: 8px;">
            <router-link to="/convertLog" style="text-decoration: none;color: black;vertical-align: middle; color: rgba(131, 129, 150, 1);">{{ $t('convert.i_convertLog') }}></router-link>
          </div>
        </div>
      </div>

    

      <!-- <div style="margin-top: 2%">Convert your Cash-Coupon to cash</div>

      <div style="margin-top: 5%;vertical-align: middle;">
        <img src="@/assets/common/cashIcon.png" style="width: 36px;vertical-align: middle;" />
        <span style="margin-left: 4px;font-size: 42px;font-weight: 800;color: rgba(211, 196, 25, 1);-webkit-text-stroke: 1.2px rgba(0, 0, 0, 1);vertical-align: middle;">{{ $store.state.cashNum }}</span>
      </div>

      <img src="@/assets/images/convertIcon.png" style="margin-top: 10%;width: 100px;" />

      <div style="margin-top: 14%;margin-bottom: 5%;margin-left: 5%;text-align: left;">
        {{ $t('convert.i_choose') }}
      </div> -->

      <div style="padding-bottom: 6%;">
        <span v-for="giftCard in this.data.giftCardList" v-bind:key="giftCard.cardId">
          <router-link :to="{ name: 'convertInfo', params: { id: giftCard.cardId } }">
            <img :src="giftCard.image" style="margin: 2%;width: 160px;border-radius: 8px;" />
          </router-link>
        </span>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{ failMessage }}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfo: {
        uid: 0,
        cashNum: 0,
        inviteCode: "",
        appName: "",
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      failMessage: null,
      data: {
        giftCardList: []
      },
      checkDialog: false
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    getGiftCardList() {
      this.isLoading = true;
      this.$axios({
        url: "/webCash/convert",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.userInfo = result.data.data.userInfo;
          this.$store.state.cashNum = result.data.data.userInfo.num;
          this.data = result.data.data;
          if (result.data.data.giftCardList != null) {
            sessionStorage.setItem(
              "giftCardList",
              JSON.stringify(result.data.data.giftCardList)
            );
          } else {
            sessionStorage.removeItem("giftCardList");
          }
        }
      });
    }
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    //this.$store.state.goldNum = this.userInfo.goldNum;
    this.failMessage = sessionStorage.getItem("failMessage");
    if (this.failMessage != null) {
      this.checkDialog = true;
    }
    this.getGiftCardList();
  }
};
</script>

<style>
#convert-layout {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 500px;
  /* background-color: white; */
}

#convert-layout .c-page-header {
  padding-top: 4%;
  padding-left: 4%;
  padding-right: 4%;
  overflow: hidden;
}
</style>
