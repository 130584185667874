<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="gold-log-layout">
      <!-- <p style="border: 1px solid rgba(240, 240, 240, 1);padding: 0;margin: 3% 0;"></p> -->
      <div style="position: relative; top: 32px;margin-left: 5%;margin-right: 5%; scroll;height: 5vh;">
          <span style="color: rgba(255, 255, 255, 1); float: left; font-size: 19px">{{$t('index.i_personal')}}</span>
          <img src="@/assets/common/return.png" @click="$router.push('/index')" style="float: right;width: 30px;margin-right: 10px;" />
      </div>
      
      <div style="margin-top: 13%;margin-left: 5%;margin-right: 5%;scroll; margin-bottom: 20px">
        <div style="background-color: rgba(40, 41, 66, 1);margin-bottom: 3%;padding: 33%;border-radius: 25px; height: 0px">
          <div style="width: 60px;height: 60px;margin: auto; margin-top: -76px; margin-left: -97px">
            <img src="@/assets/images/index/avatar.png" style="width: 60px;height: 60px;border-radius: 30px;" />
            <img :src="userInfo.avatar" style="width: 60px;height: 60px;border-radius: 30px;margin-left: -60px;" />
          </div>
          <div style="width: 100px;height: 60px;margin: auto; margin-top: -50px; margin-left: -29px">
            <div style="color: rgba(255, 255, 255, 1);text-align: left;">{{ userInfo.userName }}</div>
            <div style="margin-top: 3px">
              <td style="color: rgba(255, 255, 255, 1); text-align: right;">UID</td>
              <td style="text-align: left; color: rgba(255, 255, 255, 1);">{{ userInfo.uid }}</td>
            </div>
          </div> 
          <p style="border: 1px solid rgba(71, 72, 92, 1);padding: 0;margin: 3% 0; width: 290px; margin-top: 15px; margin-left: -90px"></p> 

          <div style="position: relative; left: -100px; top: 25px">
              <img src="@/assets/common/cashIcon.png" style="width: 33px;vertical-align: middle;" />
              <span style="position: relative; left: 5px; top: -5px;font-size: 16px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(246, 243, 254, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
              <div style=" position: relative; left: 21px;top: -15px; font-size: 13px;color: rgba(255,255,255,1)">{{$t('index.i_currentGold')}}</div>
          </div>
          <div style="background-color: rgba(80, 214, 119, 1); position: relative; top: -26px; left: 120px; width: 59px; height: 30px">
              <span @click="$router.push('/cashLog')" style="position: relative; top: 5px; color: rgba(19, 21, 34, 1)">{{$t('index.i_records')}}</span>
          </div>
        </div>
      </div>

      <div style="margin-top: 13%;margin-left: 5%;margin-right: 5%;scroll; margin-bottom: 20px">
          <div style="background-color: rgba(40, 41, 66, 1);margin-bottom: 3%;padding: 25%;border-radius: 25px; height: 0px">  
            <div style="color: rgba(255, 255, 255, 1); position: relative; margin-top: -60px;  font-size: 20px; letter-spacing: 5px; top: -15px; margin-bottom: 10px">{{$t('all.i_invite')}}</div>
            <div style="float: left; position: relative; top: 0px;  color: rgba(255, 255, 255, 1); margin-left: -39px;">{{$t('index.i_inviteFriends')}}</div>
            <span style="position: relative;float: ;  display: inline-block;text-align: left; top: 0px; margin-right: -142px" @click="$router.push('/inviteFriend')">
                    <span style="color: rgba(202, 203, 223, 1);font-size: 17px;vertical-align: middle;font-weight: bold;">+{{maxReward}}</span>
                    <img src="@/assets/common/cashIcon.png" style="width: 20px;vertical-align: middle;" />
                    <span style="position: relative; color: rgba(202, 203, 223, 1);font-size: 16px;vertical-align: middle;font-weight: bold; top: 0px; ">/ {{$t('all.i_friends')}}</span>
            </span>
            <p style="border: 1px solid rgba(71, 72, 92, 1);padding: 0;margin: 3% 0; width: 280px; margin-top: 19px; margin-left: -53px"></p> 
            
            <!-- <div style="position: relative; top:15px">
              <div style="float: left; position: relative; top: 0px; left: -60px; color: rgba(255, 255, 255, 1); margin-left: 17px">{{$t('inviteFriend.i_enterCode')}}</div>
                <span style="position: relative; display: inline-block;text-align: left; top: 0px; margin-right: -68px">
                      <span style="color: rgba(202, 203, 223, 1);font-size: 17px;vertical-align: middle;font-weight: bold;">+200</span>
                      <img src="@/assets/common/cashIcon.png" style="width: 20px;vertical-align: middle;" />
                </span>
              </div> -->
            </div>
          </div>  
          <div style="margin-top: 5%;margin-left: 5%;margin-right: 5%;height: 50vh;">
          </div>
      </div>
        <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        </div>
     
   

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{failMessage}}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfo: {
        cashNum: 0,
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      failMessage: null,
      checkDialog: false,
      cashLog: [],
      busy: false,
      pageNum: 0,
      isLast: false,
      maxReward: 0,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    getIndex() {
      
      this.isLoading = true;
      this.$axios({
        url: "/webCash/index",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.maxReward = result.data.data.maxReward;
          this.userInfo = result.data.data.userInfo;
          // this.failMessage = result.data.data.failMessage;
          this.$store.state.cashNum = result.data.data.userInfo.num;
          this.todayCashLeaderboard =
            result.data.data.leaderboard.todayCashLeaderboard;
          if (result.data.data.leaderboard.todayCashLeaderboard == null) {
            this.leaderboardType = 2;
          }
          this.cashLeaderboard = result.data.data.leaderboard.cashLeaderboard;
          this.friendLeaderboard =
            result.data.data.leaderboard.friendLeaderboard;
          if (result.data.data.userInfo != null) {
            sessionStorage.setItem(
              "userInfo",
              JSON.stringify(result.data.data.userInfo)
            );
          } else {
            sessionStorage.removeItem("userInfo");
          }
        }
      });
    },
  },
  created() {
    this.getIndex()
  }
};
</script>

<style>
#gold-log-layout {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 500px;
  background-color: rgba(19, 21, 34, 1);
}
#gold-log-layout .gl-page-header {
  overflow: hidden;
  padding: 4% 4% 0 4%;
}
</style>
