<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 首页版心 -->
    <div id="index-layout" style="background-color: rgba(1, 1, 1, 1);">
      <div class="index-top">
        <!-- 导航条 -->
        <div class="i-page-header" >
          <img src="@/assets/common/bigbigcash2Icon.png"  style="float: left;width: 135px; margin-bottom: 12px;" />
          <div style="float: right;">
            <div style="background-color: rgba(19, 21, 34, 1);padding: 3px 7px 4px 7px;border-radius: 20px;display: inline-block;vertical-align: middle;margin-right: 10px;">
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
              <span style="font-size: 16px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(246, 243, 254, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
            </div>
            <div @click="$router.push('/personal')" style="width: 36px;height: 36px;display: inline-block;vertical-align: middle;">
              <img src="@/assets/images/index/avatar.png" style="width: 36px;height: 36px;border-radius: 30px;" />
              <img :src="userInfo.avatar" style="width: 36px;height: 36px;border-radius: 30px;margin-left: -36px;" />
            </div>
          </div>
        </div>


        <div style="position: relative; overflow: hidden;margin: auto 4%; top: 25px;">
          <div @click="$router.push('/convert')" class="background-container" style="float: left;padding: 3%;border-radius: 15px;text-align: left;width: 41%; height: 71px">
            <span style="font-size: 12px;color: rgba(255, 255, 255, 1);">{{$t('all.i_variousGifts')}}</span>
            <br />
            <span style="font-size: 16px;color: rgba(255, 255, 255, 1);font-weight: bold;">{{$t('all.i_redeemNow')}}</span>
            <br />
            
          </div>
          <div @click="$router.push('/cashLog')" class="background-container1" style="float: right;padding: 3%;border-radius: 15px;text-align: left;width: 41%; height: 83px">
            <span style="font-size: 12px;color: rgba(255, 255, 255, 1);">{{$t('all.i_couponDetails')}}</span>
            <br />
            <span style="font-size: 16px;color: rgba(255, 255, 255, 1);font-weight: bold;">{{$t('all.i_rewardRecords')}}</span>
            <br />
            
          </div>
        </div>


        <!-- 广告 -->
        <div style="margin-top: 37px; margin-bottom: -45px">
          <swiper :options="swiperOptions" @click="handleClick">
            <swiper-slide v-for="(item, index) in ads" :key="index" >
              <a :href="item.actionUrl" target="_blank">
                <div  style="width:100%; heigh: 200%; object-fit: cover;">
                  <img :src="item.backgroundImg" style="width: 93%; border-radius: 12px;">
                </div>
              </a>
            </swiper-slide>
            
            <div class="swiper-pagination top-ad" slot="pagination"></div>
            
          </swiper>
        </div>

        <div style="position: relative; text-align: left; padding: 4%; top: 45px" >
          <img src="@/assets/images/index/leaderboardIcon.png" style="width: 26px;vertical-align: middle;" />
          <span style="position: relative; font-size: 21px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(255, 255, 255, 1);left: 7px;">{{$t('all.i_leaderboard')}}</span>
        </div>

        <div style="background: linear-gradient(180deg, rgba(68, 62, 110, 1) 0%, rgba(34, 33, 51, 1) 100%);margin: 4%;padding: 4% 4%;border-radius: 15px; margin-top: 13%">
          <div style="text-align: left;">
            <div style="font-size: 12px;margin-left: -5px;margin-top: 12px;margin-bottom: 12px;">
              <span :class="leaderboardType == 1 ? 'leaderboardAct' : ''" @click="leaderboardType = 1;" style="margin: auto 4px;padding: 4px 8px 4px 6px; color: rgba(178, 177, 209, 1)">{{$t('all.i_todayReward')}}</span>
              <span :class="leaderboardType == 2 ? 'leaderboardAct' : ''" @click="leaderboardType = 2;" style="margin: auto 4px;padding: 4px 8px 4px 6px; color: rgba(178, 177, 209, 1)">{{$t('all.i_totalReward')}}</span>
              <span :class="leaderboardType == 3 ? 'leaderboardAct' : ''" @click="leaderboardType = 3;" style="margin: auto 4px;padding: 4px 8px 4px 6px; color: rgba(178, 177, 209, 1)">{{$t('all.i_friends')}}</span>
            </div>
          </div>
          <div v-if="leaderboardType == 1">
            <div v-for="(leaderboard, index) in todayCashLeaderboard" :key="leaderboard.id">
              <div style="overflow: hidden;margin: 3% auto;">
                <div style="float: left;background-color: rgba(35, 53, 63, 1);width: 45px;height: 45px;border-radius: 30px;position: relative;margin-top: 8px;margin-left: 8px;">
                  <img :src="leaderboard.avatar" style="width: 100%;border-radius: 30px;background-color: white;" />
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: -6px;top: -6px;background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                    <span style="vertical-align: middle;font-size: 12px;">{{index + 1}}</span>
                  </span>
                </div>
                <div style="float: left;margin-left: 20px;margin-top: 12px;text-align: left;">
                  <span style="color: rgba(255, 255, 255,1);font-size: 15px;font-weight: 600;">{{leaderboard.userName}}</span>
                  <br />
                  <span style="color: rgba(80, 214, 119, 1);font-size: 13px;">{{leaderboard.num}}</span>
                  <img src="@/assets/common/cash1Icon.png" style="width: 14px;vertical-align: middle;" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="leaderboardType == 2">
            <div v-for="(leaderboard, index) in cashLeaderboard" :key="leaderboard.id">
              <div style="overflow: hidden;margin: 3% auto;">
                <div style="float: left;background-color: rgba(36, 76, 49, 1);width: 45px;height: 45px;border-radius: 30px;position: relative;margin-top: 8px;margin-left: 8px;">
                  <img :src="leaderboard.avatar" style="width: 100%;border-radius: 30px;background-color: white;" />
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: -6px;top: -6px;background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                    <span style="vertical-align: middle;font-size: 12px;">{{index + 1}}</span>
                  </span>
                </div>
                <div style="float: left;margin-left: 20px;margin-top: 12px;text-align: left;">
                  <span style="color: rgba(255, 255, 255,1);font-size: 15px;font-weight: 600;">{{leaderboard.userName}}</span>
                  <br />
                  <span style="color: rgba(80, 214, 119, 1);font-size: 13px;">{{leaderboard.num}}</span>
                  <img src="@/assets/common/cashIcon.png" style="position: relative; width: 14px;vertical-align: middle; left: 7px" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="leaderboardType == 3">
            <div v-for="(leaderboard, index) in friendLeaderboard" :key="leaderboard.id">
              <div style="overflow: hidden;margin: 3% auto;">
                <div style="float: left;background-color: rgba(36, 76, 49, 1);width: 45px;height: 45px;border-radius: 30px;position: relative;margin-top: 8px;margin-left: 8px;">
                  <img :src="leaderboard.avatar" style="width: 100%;border-radius: 30px;background-color: white;" />
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: -6px;top: -6px;background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                    <span style="vertical-align: middle;font-size: 12px;">{{index + 1}}</span>
                  </span>
                </div>
                <div style="float: left;margin-left: 20px;margin-top: 12px;text-align: left;">
                  <span style="color: rgba(255, 255, 255,1);font-size: 15px;font-weight: 600;">{{leaderboard.userName}}</span>
                  <br />
                  <span style="color: rgba(80, 214, 119, 1);font-size: 13px;">{{leaderboard.num}}</span>
                  <img src="@/assets/images/leaderboard003.png" style="position: relative; width: 10px;vertical-align: middle; left: 5px" />
                </div>
              </div>
            </div>
          </div>
          <el-button @click="$router.push('/leaderboard')" style="width: 100%;border-radius: 10px;background-color: rgba(80, 214, 119, 1);color: rgba(1, 1, 1, 1);">{{$t('all.i_viewLeaderboard')}}</el-button>
        </div>
        
        <div>
          <div class="background-container2" style="margin: 4%;padding: 30% 4%;border-radius: 15px; margin-top: 10%">
            <div style= "margin-top: -86px;" >
              <img src="@/assets/images/invite/inviteIcon.png" style="position: relative; width: 20px;vertical-align: middle; left: -8px" />
              <span style="color: rgba(80, 214, 119, 1);font-size: 20px;">{{$t('inviteFriend.i_inviteFriend')}}</span>
              <img src="@/assets/images/invite/inviteIcon.png" style="position: relative; width: 20px;vertical-align: middle; left: 5px" />
            </div>
          <div style="position: relative; display: inline-block;text-align: left; top: 37px;">
                    <span style="color: rgba(255, 255, 255, 1);font-size: 29px;vertical-align: middle;font-weight: bold;">{{maxReward}}</span>
                    <img src="@/assets/common/cashIcon.png" style="width: 23px;vertical-align: middle;" />
          </div>
                  <span style="position: relative; color: rgba(255, 255, 255, 1);font-size: 27px;vertical-align: middle;font-weight: bold; top: 35px; left: 10px;">/{{$t('all.i_friends')}}</span>
          </div>
                <el-button @click="$router.push('/inviteFriend')" style="position: relative; top: -57px; width: 80%;border-radius: 10px;background-color: rgba(80, 214, 119, 1);color: rgba(1, 1, 1, 1);">{{$t('inviteFriend.i_inviteNow')}}</el-button> 
        </div>

        <div style="margin: 4%;">
          <div style=" text-align: left;">
             <img src="@/assets/images/task/taskIcon.png" style="width: 30px;vertical-align: left;" />
             <span style="position: relative; left: 13px; font-size: 25px;text-align: left;color: rgba(255, 255, 255, 1)">{{$t('all.i_task')}}</span>
          </div>
         

          <div style="margin-top: 20px" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
            <div style="display: flex; flex-wrap: wrap; gap: 10px;">
              <div v-for="(task) in taskList" :key="task.id" style="flex: 1 1 calc(50% - 10px); box-sizing: border-box;">
                <div style="overflow: hidden; margin: 0; padding: 0; background: linear-gradient(180deg, rgba(68, 62, 110, 1) 0%, rgba(48, 49, 75, 1) 100%); border-radius: 10px; height: 290px;">
                  <div style="float: left; height: 60px;">
                    <img @click="$router.push({ path: '/taskInfo', query: { tid: task.id } })" :src="task.appIcon" style="position: relative; width: 135px; height: 135px; top: 15px; left: 5px; margin-right: 10px; border-radius: 12px;" />
                    <div @click="$router.push({ path: '/taskInfo', query: { tid: task.id } })" style="display: inline-block; vertical-align: top; margin-top: 20px">
                      <span v-html="task.title" style="color: rgba(124, 120, 176, 1); font-size: 15px; font-weight: bold; float: left; margin-bottom: 6px; width: 135px; height: 50px; line-height: 21px; text-align: left; overflow: hidden; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;"></span>
                      <br />
                      <el-progress v-if="task.curTaskNum == 0" :show-text="false" :stroke-width="10" :percentage="0" :color="customColors" style="width: 100%; float: left; vertical-align: middle; margin-top: 4px;"></el-progress>
                      <el-progress v-else :show-text="false" :stroke-width="10" :percentage="(task.curTaskNum / task.totalTaskNum) * 100" :color="customColors" style="width: 100%; float: left; vertical-align: middle; margin-top: 4px;"></el-progress>
                      <div style="clear: both; color: rgba(255, 255, 255, 1); font-size: 19px; vertical-align: middle; margin-top: 20px; float: left;">
                        +{{ task.rewardTotalCash }}
                      </div>
                      <img src="@/assets/common/cashIcon.png" style="position: relative; top: 20px; width: 20px; vertical-align: middle; left: -15px;" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="taskList.length % 2 === 1" style="flex: 1 1 calc(50% - 10px); box-sizing: border-box;"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- 联系我们 -->
      <div @click="contactDrawer = true" style="position: fixed;top: 55%;right: 2%;">
        <img src="@/assets/images/index/contactIcon.png" style="width: 40px;" />
      </div>

      <!-- 联系我们Drawer -->
      <el-drawer size="58%" direction="btt" :with-header="false" :show-close="false" :visible.sync="contactDrawer">
        <div>
          <div style="overflow: hidden;margin-top: 5%;margin-left: 5%;margin-right: 5%;">
            <span style="float: left;font-size: 20px;color: black;">{{ $t('index.i_contact') }}</span>
          </div>
          <div style="margin-top: 6%;">
            <img src="@/assets/images/index/goBrowser.png" style="width: 100px;" />
            <div style="margin-top: 2%;margin-bottom: 5%;color: rgba(127, 127, 149, 1);">{{ $t('index.i_browserOpen') }}</div>
          </div>
          <hr style="width: 80%;" />
          <div style="margin: auto;margin-top: 4%;margin-bottom: 6%;">
            <!-- <div>
              <span @click="goUrl(whatsApp)">
                <img src="@/assets/common/whatsApp.png" style="width: 50px;vertical-align: middle;" />
                <div style="display: inline-block;vertical-align: middle;margin-top: 6px;margin-left: 10px;margin-right: 10px;">
                  <p style="margin: 0;padding: 0;text-align: left;font-weight: bold;">WhatsApp</p>
                  <a :href="whatsApp" target="_blank" style="margin: 0;padding: 0;text-align: left;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;width: 150px;">{{ whatsApp }}</a>
                </div>
              </span>
              <el-button round type="primary" @click="copyVal(whatsApp)" style="width: 30%;background-color: rgba(0, 111, 255, 1);vertical-align: middle;">
                <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
                &nbsp;
                <span style="font-size: 16px;">Copy</span>
              </el-button>
            </div> -->
            <!-- <div style="margin: 4% auto;">
              <span @click="goUrl(meta)">
                <img src="@/assets/common/meta.png" style="width: 50px;vertical-align: middle;" />
                <div style="display: inline-block;vertical-align: middle;margin-top: 6px;margin-left: 10px;margin-right: 10px;">
                  <p style="margin: 0;padding: 0;text-align: left;font-weight: bold;">Meta</p>
                  <a :href="meta" target="_blank" style="margin: 0;padding: 0;text-align: left;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;width: 150px;">{{meta}}</a>
                </div>
              </span>
              <el-button round type="primary" @click="copyVal(meta)" style="width: 30%;background-color: rgba(0, 111, 255, 1);vertical-align: middle;">
                <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
                &nbsp;
                <span style="font-size: 16px;">Copy</span>
              </el-button>
            </div> -->
            <div>
              <span @click="goUrl(telegram)">
                <img src="@/assets/common/telegram.png" style="width: 50px;vertical-align: middle;" />
                <div style="display: inline-block;vertical-align: middle;margin-top: 6px;margin-left: 10px;margin-right: 10px;">
                  <p style="margin: 0;padding: 0;text-align: left;font-weight: bold;">Telegram</p>
                  <a :href="telegram" target="_blank" style="margin: 0;padding: 0;text-align: left;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;width: 150px;">{{ telegram }}</a>
                </div>
              </span>
              <el-button round type="primary" @click="copyVal(telegram)" style="width: 30%;background-color: rgba(0, 111, 255, 1);vertical-align: middle;">
                <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
                &nbsp;
                <span style="font-size: 16px;">Copy</span>
              </el-button>
            </div>
          </div>
        </div>
      </el-drawer>
      <!-- 账户中心 -->
      <el-dialog :title="$t('index.i_accountC')" :visible.sync="userInfoDialog" width="80%" center>
        <div style="width: 100%;text-align: center;background-color: rgba(244, 245, 247, 1);padding-top: 9%;padding-bottom: 8%;border-radius: 20px;">
          <div style="width: 60px;height: 60px;margin: auto;">
            <img src="@/assets/images/index/avatar.png" style="width: 60px;height: 60px;border-radius: 30px;" />
            <img :src="userInfo.avatar" style="width: 60px;height: 60px;border-radius: 30px;margin-left: -60px;" />
          </div>
          <table style="margin: auto;font-size: 18px;color: rgba(0, 0, 0, 1);margin-top: 10px;">
            <tr>
              <td style="text-align: right;">UID：</td>
              <td style="text-align: left;">{{ userInfo.uid }}</td>
            </tr>
            <tr>
              <td style="text-align: right;">{{$t('all.i_nickName')}}：</td>
              <td style="text-align: left;">{{ userInfo.userName }}</td>
            </tr>
            <tr v-if="userInfo.inviteCode != null">
              <td style="text-align: right;">{{ $t('index.i_inviteCode') }}：</td>
              <td style="text-align: left;">
                <span @click="copyVal(userInfo.inviteCode)">{{ userInfo.inviteCode }}</span>
                <span style="margin-left: 4px;" @click="mobileInfoInit">
                  <img src="@/assets/common/copyIconB.png" style="margin-bottom: 10px;width: 10px;" />
                </span>
              </td>
            </tr>
          </table>
        </div>
      </el-dialog>

      <!-- 错误信息Dialog -->
      <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnPressEscape="false">
        <div style="text-align: center;margin-bottom: 10%;">
          <div style="font-size: 16px;color: #000;">{{ failMessage }}</div>
        </div>
        <div style="text-align: center;">
          <el-button round type="primary" @click="go()" style="width: 60%;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import DeviceDetector from "device-detector-js";

export default {
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      userInfo: {
        uid: 0,
        avatar: "",
        cashNum: 0,
        inviteCode: "",
        appName: "",
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      maxReward: 0,
      failMessage: null,
      checkDialog: false,
      userInfoDialog: false,
      contactDrawer: false,
      // whatsApp: "https://chat.whatsapp.com/BsP9LYv73k2E6NpjphgAUf",
      // meta: "https://www.facebook.com/profile.php?id=61554439329069",
      telegram: "https://t.me/+6iNEoHf2HaYxM2Zl",
      contact: "https://www.facebook.com/people/Puzzle-Story/100089158787963/",
      leaderboardType: 1,
      customColors: "rgba(237, 179, 77, 1)",
      taskList: [],
      busy: false,
      pageNum: 0,
      isLast: false,
      todayCashLeaderboard: [],
      cashLeaderboard: [],
      friendLeaderboard: [],
      ads: [],
      swiperOptions: {
        pagination: {
          el: '.top-ad',
          clickable: true
        },
        autoplay: {
        delay: 15000, // 每5秒自动切换
        disableOnInteraction: false, // 交互后继续自动播放
        },
        loop: true, // 循环播放
        // 其他选项...
        //  on:{
        //   click: function(event){
        //     console.log((this.activeIndex));
        //     a(this.activeIndex)
        //   },
        // },
      },
      length: 0
    };
  },

  
  methods: {
    handleClick(event) {
      
      const slide = event.target.closest('.swiper-slide')
      const adId = this.ads[slide.getAttribute('data-swiper-slide-index')].adId
      this.add(adId)
    },
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    },

    mobileInfoInit() {
    // 设备信息
    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    let device = deviceDetector.parse(userAgent);
    
    console.log(device)
    },

    getIndex() {
      
      this.isLoading = true;
      this.$axios({
        url: "/webCash/index",
        method: "get",
        params: {
            inviteCode: this.$route.query.referrer
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.maxReward = result.data.data.maxReward;
          this.userInfo = result.data.data.userInfo;
          // this.failMessage = result.data.data.failMessage;
          this.$store.state.cashNum = result.data.data.userInfo.num;
          this.todayCashLeaderboard =
            result.data.data.leaderboard.todayCashLeaderboard;
          if (result.data.data.leaderboard.todayCashLeaderboard == null) {
            this.leaderboardType = 2;
          }
          this.cashLeaderboard = result.data.data.leaderboard.cashLeaderboard;
          this.friendLeaderboard =
            result.data.data.leaderboard.friendLeaderboard;
          if (result.data.data.userInfo != null) {
            sessionStorage.setItem(
              "userInfo",
              JSON.stringify(result.data.data.userInfo)
            );
          } else {
            sessionStorage.removeItem("userInfo");
          }
        }
      });
    },
    goApp() {
      window.location.href = this.userInfo.appScheme;
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    goUrl(url) {
      window.open(url, "_blank");
    },


    loadMore: function () {
      if (this.isLast != true) {       
        this.busy = true;
        this.pageNum++;
        this.isLoading = true;  
        this.$axios({
          url: "/webCash/taskList",
          method: "get",
          params: {
            pageNum: this.pageNum,
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {           
            result.data.data.taskList.forEach((element) => {
              this.taskList.push(element);
            });
            this.isLast = result.data.data.isLast;
          }
          
        });
        this.busy = false;
        if (this.failMessage != null) {
          this.checkDialog = true;
        }
      }
    },

    getAds() {
        this.$axios({
          url: "/webCash/adList",
          method: "get",
          params: {
            tagId: 0,
          }
        }).then((result) => {  
          if (result.data.code == 1) {           
            result.data.data.ads.forEach((element) => {
              this.ads.push(element);
            });
            this.length = this.ads.length;
          }  
          
        });
        
        if (this.failMessage != null) {
          this.checkDialog = true;
        }
    },

    add(adId) {
        this.$axios({
          url: "/webCash/add",
          method: "get",
          params: {
            operateType: 2,
            adId: adId,
          }
        }).then((result) => {  
           
        });

    },
    
  },

  created() {
    // if (this.$route.query.token != null) {
    //   localStorage.setItem("token", this.$route.query.token);
    // }
    
    this.getAds();
    this.getIndex();
    this.loadMore();
    
  }
};
</script>

<style>
#index-layout {
  max-width: 500px;
  margin: auto;
}

#index-layout .index-top {
  padding-bottom: 2%;
}

#index-layout .i-page-header {
  overflow: hidden;
  padding: 8% 4% 0 4%;
  background-color: rgba(28, 29, 47, 1)
}

#index-layout .el-page-header__left::after {
  display: none;
}

#index-layout .i-gift-code-btn,
#index-layout .bottom-menu-bar .el-button {
  border: none;
  background: none;
}

#index-layout .el-drawer {
  border-radius: 20px 20px 0 0 !important;
}

#index-layout .leaderboardAct {
  background-color: rgba(80, 270, 119, 1);
  color: rgba(35, 53, 63, 1)!important;
  border-radius: 30px;
}

.background-container {
  background-image: url('@/assets/images/index/convertIcon.png'); /* 替换为你的图片路径 */
  background-size: cover; /* 使背景图像覆盖整个容器 */
  background-position: center; /* 将图像居中显示 */
  background-repeat: no-repeat; /* 防止图像重复 */
  /* 如果需要，可以保留颜色作为备选背景 */
  
}
.background-container1 {
  background-image: url('@/assets/images/index/logIcon.png'); /* 替换为你的图片路径 */
  background-size: cover; /* 使背景图像覆盖整个容器 */
  background-position: center; /* 将图像居中显示 */
  background-repeat: no-repeat; /* 防止图像重复 */
  /* 如果需要，可以保留颜色作为备选背景 */
  
}
.background-container2 {
  background-image: url('@/assets/images/invite/invitebackground.png'); /* 替换为你的图片路径 */
  background-size: 392px 200px;; /*使背景图像覆盖整个容器  */
  background-position: center; /* 将图像居中显示 */
  background-repeat: no-repeat; /*防止图像重复 */
  
}

.swiper-pagination.top-ad {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
}


</style>
