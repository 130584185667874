import Vue from "vue";
import VueRouter from "vue-router";
import Convert from "@/views/Convert.vue";
import ConvertLog from "@/views/ConvertLog.vue";
import CashLog from "@/views/CashLog.vue";
import Index from "@/views/Index.vue";
import InviteFriend from "@/views/InviteFriend.vue";
import ConvertInfo from "@/views/ConvertInfo.vue";
import Leaderboard from "@/views/Leaderboard.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import Login from "@/views/Login.vue";
import TaskInfo from "@/views/TaskInfo.vue";
import Invite from "@/views/invite/Invite.vue";
import Personal from "@/views/Personal.vue";
import InviteLog from "@/views/InviteLog.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "index"
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/taskInfo",
    name: "taskInfo",
    component: TaskInfo
  },
  {
    path: "/BigbigCash2/invite",
    name: "invite",
    component: Invite
  },
  {
    path: "/convert",
    name: "convert",
    component: Convert
  },
  {
    path: "/convertLog",
    name: "convertLog",
    component: ConvertLog
  },
  {
    path: "/cashLog",
    name: "cashLog",
    component: CashLog
  },
  {
    path: "/index",
    name: "index",
    component: Index
  },
  {
    path: "/inviteFriend",
    name: "inviteFriend",
    component: InviteFriend
  },
  {
    path: "/convertInfo/:id",
    name: "convertInfo",
    props: true,
    component: ConvertInfo
  },
  {
    path: "/leaderboard",
    name: "leaderboard",
    component: Leaderboard
  },
  {
    path: "/personal",
    name: "personal",
    component: Personal
  },
  {
    path: "/inviteLog",
    name: "inviteLog",
    component: InviteLog
  },
  {
    path: "/errorPage",
    name: "errorPage",
    component: ErrorPage
  }
];

// 修复跳转页面，页面不置顶的问题
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { x: 0, y: 0 };
};

const router = new VueRouter({
  routes,
  scrollBehavior
});

// router.beforeEach((to, from, next) => {
//   console.log(to);
//   if (to.path != "/login" && to.path != "/invite") {
//     let token = localStorage.getItem("token");
//     if (token == null) {
//       router.push({
//         path: "/login",
//         // query: { tid: to.query.tid, referrer: to.query.referrer }
//       });
//     }
//   }
//   next();
// });

export default router;
