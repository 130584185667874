<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="gold-log-layout">
      <div class="gl-page-header">
        <div style="float: left;">
          <div style="float: right;background-color: rgba(19, 21, 34, 1);padding: 3px 7px 4px 7px;border-radius: 20px;margin: 6px 0 0 0;">
            <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
            <span style="font-size: 14px;color: black;text-decoration: none;vertical-align: middle;margin-right: 2px;color: rgba(255, 255, 255, 1);font-weight: 600;">
              {{ $store.state.cashNum }}
            </span>
            
          </div>
        </div>
      </div>

      <!-- <p style="border: 1px solid rgba(240, 240, 240, 1);padding: 0;margin: 3% 0;"></p> -->
      <div style="margin-top: 5%;margin-left: 5%;margin-right: 5%;height: 5vh;">
          <span style="color: rgba(255, 255, 255, 1); float: left; font-size: 18px">{{$t('inviteFriend.i_inviteLog')}}</span>
          <img src="@/assets/common/return.png" @click="$router.push('/InviteFriend')" style="float: right;width: 30px;margin-right: 10px;" />
      </div>
      <div style="margin-top: 5%;margin-left: 5%;margin-right: 5%;overflow-y: scroll;height: 84vh;">
        <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
          <div v-for="log in inviteLog" :key="log.id" style="background-color: rgba(40, 41, 66, 1);margin-bottom: 3%;padding: 4%;border-radius: 8px;">
            <div style="overflow: hidden;color: rgba(0, 0, 0, 1);">
              <div style="float: left;margin-top: 3%;font-size: 26px;">
                <div  style="float: left; position: relative; top: 5px; color: rgba(80, 214, 119, 1);">+{{log.reward}}</div>
                <img v-if="log.reward == 0" src="@/assets/common/cashIcon.png" style="margin-left: -50px;width: 24px; position: relative; top: 6px; " />
                <img v-else src="@/assets/common/cashIcon.png" style="margin-left: 4px;width: 24px; position: relative; top: 6px;" />
                <div v-if="log.reward == 0" style="color: rgba(80, 214, 119, 1); font-size: 12px; margin-top: 5px">Step 3 not completed</div>
              </div>
              
              <div style="float: right;text-align: right;color: rgba(255, 255, 255, 1);">
                {{$t('leaderboard.i_friends')}}UID {{log.uid}}
                <div style="margin-top: 10%; color: rgba(255, 255, 255, 1);font-size: 13px;">
                  {{log.date}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="inviteLog == ''">
          <img src="@/assets/common/noIcon.png" style="margin-top: 30%;width: 150px;" />
          <div style="margin-top: 5%;color: rgba(161, 180, 189, 1);">{{$t('inviteFriend.i_noLog')}}</div>
        </div>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{failMessage}}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfo: {
        cashNum: 0,
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      failMessage: null,
      checkDialog: false,
      busy: false,
      pageNum: 0,
      isLast: false,
      inviteLog: []
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    loadMore: function () {
      if (this.isLast != true) {
        this.busy = true;
        this.pageNum++;
        this.isLoading = true;
        this.$axios({
          url: "/webCash/invite/log",
          method: "get",
          params: {
            pageNum: this.pageNum,
            
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            if (this.pageNum == 1) {
              this.userInfo = result.data.data.userInfo;
              this.$store.state.cashNum = result.data.data.userInfo.num;
            }
             result.data.data.items.forEach((element) => {
              this.inviteLog.push(element);
            });
            this.isLast = result.data.data.isLast;
          }
        });
        this.busy = false;
        if (this.failMessage != null) {
          this.checkDialog = true;
        }
      }
    }
  },
  created() {
    cashNum = $store.state.cashNum
  }
};
</script>

<style>
#gold-log-layout {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 500px;
  background-color: rgba(19, 21, 34, 1);
}
#gold-log-layout .gl-page-header {
  overflow: hidden;
  padding: 4% 4% 0 4%;
}
</style>
